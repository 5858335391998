@font-face {
  font-family: 'ProximaNovaA';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/proximanova/ProximaNovaA-BoldIt.eot');
  src:
    url('/fonts/proximanova/ProximaNovaA-BoldIt.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNovaA-BoldIt.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNovaA-BoldIt.woff') format('woff'),
    url('/fonts/proximanova/ProximaNovaA-BoldIt.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaA';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/proximanova/ProximaNovaA-Bold.eot');
  src:
    url('/fonts/proximanova/ProximaNovaA-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNovaA-Bold.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNovaA-Bold.woff') format('woff'),
    url('/fonts/proximanova/ProximaNovaA-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaS';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/proximanova/ProximaNovaS-BoldIt.eot');
  src:
    url('/fonts/proximanova/ProximaNovaS-BoldIt.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNovaS-BoldIt.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNovaS-BoldIt.woff') format('woff'),
    url('/fonts/proximanova/ProximaNovaS-BoldIt.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaS';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/proximanova/ProximaNovaS-Bold.eot');
  src:
    url('/fonts/proximanova/ProximaNovaS-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNovaS-Bold.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNovaS-Bold.woff') format('woff'),
    url('/fonts/proximanova/ProximaNovaS-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/proximanova/ProximaNova-BoldIt.eot');
  src:
    url('/fonts/proximanova/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNova-BoldIt.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNova-BoldIt.woff') format('woff'),
    url('/fonts/proximanova/ProximaNova-BoldIt.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/proximanova/ProximaNova-Bold.eot');
  src:
    url('/fonts/proximanova/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNova-Bold.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNova-Bold.woff') format('woff'),
    url('/fonts/proximanova/ProximaNova-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaA';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/proximanova/ProximaNovaA-Regular.eot');
  src:
    url('/fonts/proximanova/ProximaNovaA-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNovaA-Regular.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNovaA-Regular.woff') format('woff'),
    url('/fonts/proximanova/ProximaNovaA-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaA';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/proximanova/ProximaNovaA-RegularIt.eot');
  src:
    url('/fonts/proximanova/ProximaNovaA-RegularIt.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNovaA-RegularIt.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNovaA-RegularIt.woff') format('woff'),
    url('/fonts/proximanova/ProximaNovaA-RegularIt.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaS';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/proximanova/ProximaNovaS-Regular.eot');
  src:
    url('/fonts/proximanova/ProximaNovaS-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNovaS-Regular.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNovaS-Regular.woff') format('woff'),
    url('/fonts/proximanova/ProximaNovaS-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaS';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/proximanova/ProximaNovaS-RegularIt.eot');
  src:
    url('/fonts/proximanova/ProximaNovaS-RegularIt.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNovaS-RegularIt.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNovaS-RegularIt.woff') format('woff'),
    url('/fonts/proximanova/ProximaNovaS-RegularIt.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/proximanova/ProximaNova-Regular.eot');
  src:
    url('/fonts/proximanova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNova-Regular.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNova-Regular.woff') format('woff'),
    url('/fonts/proximanova/ProximaNova-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/proximanova/ProximaNova-RegularIt.eot');
  src:
    url('/fonts/proximanova/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
    url('/fonts/proximanova/ProximaNova-RegularIt.woff2') format('woff2'),
    url('/fonts/proximanova/ProximaNova-RegularIt.woff') format('woff'),
    url('/fonts/proximanova/ProximaNova-RegularIt.ttf') format('truetype');
}
