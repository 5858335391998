/* UT Style Variables */
@import '../colors';

$primary:       #82BC00;
$secondary:     #343d46;
$tertiary:      #178041;
$light:         #E5E5E5;

$state-theme-colors: (
  "tertiary": $tertiary
);
$theme-colors: map-merge($theme-colors, $state-theme-colors);

$font-family-base:  'ProximaNova', sans-serif;
$headings-font-family: 'ProximaNova', sans-serif;

/* Primary Page-Styling Variables */
$body-bg:                           #777;
$body-color:                        #333;

$navbar-bg:                         $light;

$footer-bg:                         $light;
$footer-nav-link-color:             $secondary;

$btn-mbl-color:                     $primary;

// Static Page Styling
$jumbotron-bg:                      hsla(0,0%,99%,.67);

$nav-tabs-link-color:               $tertiary;
$nav-tabs-link-active-color:        #000;
$nav-tabs-link-hover-color:         #000;

//Process Page Styling
$process-page-themes: (
  "claim": $secondary,
  "holder": $secondary
) !default;

$btn-action-bg:                     $primary;
$btn-pop-bg:                        $primary;

$tooltip-icon-color:                $secondary;

$dropdown-bg:                       $light;
$dropdown-hover-bg:                 $primary;
$dropdown-divider-bg:               $secondary;

$dropdown-link-color:               $secondary;
$dropdown-link-hover-color:         $secondary;
$dropdown-link-hover-bg:            $primary;

$mobile-navbar-bg:                  $light;
$mobile-nav-link-color:             $secondary;
$static-page-header-color:          $tertiary;
$static-page-subtitle-color:        #333;

/* Image Size & Image Source Variables */
$banner-image-url:                  url('/images/banner.jpg');
$body-background-image-url:         url('/images/bg-texture.png');

$claim-header-logo-url:             url('/images/UT_process_logo.png');
$claim-header-logo-width:           190px;
$claim-header-logo-height:          80px;

$holder-header-logo-url:             $claim-header-logo-url;
$holder-header-logo-width:           $claim-header-logo-width;
$holder-header-logo-height:          $claim-header-logo-height;

$home-logo-url:                     url('/images/home_logo.png');
$home-logo-width:                   620px;
$home-logo-height:                  100px;
$home-logo-resize-value-map:        (width: 80px,
                                     height: $home-logo-height,
                                     background-image: $home-logo-url);
$home-logo-resize-lg-value-map:     (width: 380px,
                                     height: $home-logo-height,
                                     background-image: $home-logo-url);

$alternate-home-logo-url:                 $home-logo-url;
$alternate-home-logo-width:               $home-logo-width;
$alternate-home-logo-height:              $home-logo-height;
$alternate-home-logo-resize-value-map:    $home-logo-resize-value-map;
$alternate-home-logo-resize-lg-value-map: $home-logo-resize-lg-value-map;

$treasurer-logo-url:                 url('/images/treasurer_logo.png');
$treasurer-logo-width:               366px;
$treasurer-logo-height:              85px;
$treasurer-logo-resize-value-map:   (width: $treasurer-logo-width,
                                     height: $treasurer-logo-height,
                                     background-image: $treasurer-logo-url);

$footer-logo-url:                   url('/images/UT_seal.png');
$footer-logo-width:                 150px;
$footer-logo-height:                150px;

$claimform-image-url:               url('/images/UT_claim_form_img.png');

$icon-email-url:                    url('/images/icon_email.png');
$icon-facebook-url:                 url('/images/icon_facebook.png');
$icon-twitter-url:                  url('/images/icon_twitter.png');
$icon-youtube-url:                  url('/images/icon_youtube.png');

// needed for testing
$icon-instagram-url:                none;
$icon-linkedin-url:                 none;
$icon-pinterest-url:                none;

$social-icon-width:                 25px;
$social-icon-height:                25px;

$carousel-control-hover-opacity:    0;
$carousel-control-opacity:          0;


.ut-video {
  height: 275px;

  @media (min-width: 576px) {
    width: 300px;
  }
  @media (min-width: 768px) {
    width: 450px;
  }
}
